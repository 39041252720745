import './App.css';
import React, { useState, useEffect, useRef } from 'react';
import logo from "../images/EMLOGO.png";
import searay from "../images/Searay.png";
import lagoon from "../images/Lagoon.png";
import monterey from "../images/Mont.png";

function useWindowWidth() { // This function listens for resize
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return width;
}


function HomePage() {

  const carouselRef = useRef(null);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    const carousel = carouselRef.current;
    
    // Function to auto-scroll the carousel
    const autoScrollCarousel = () => {
      if (carousel && !isPaused) {
        const cardWidth = carousel.querySelector('.card').offsetWidth + 16; // width + gap
        carousel.scrollBy({ left: cardWidth, behavior: 'smooth' });

        // If reached the end, scroll back to the beginning
        if (carousel.scrollLeft + carousel.clientWidth >= carousel.scrollWidth) {
          carousel.scrollTo({ left: 0, behavior: 'smooth' });
        }
      }
    };

    // Set interval for auto-scrolling every 3 seconds
    const scrollInterval = setInterval(autoScrollCarousel, 3000);

    // Cleanup the interval on component unmount
    return () => clearInterval(scrollInterval);
  }, [isPaused]);
  

  const [isOpen, setIsOpen] = useState(false);

  const [isOpen2, setIsOpen2] = useState(null);

  const toggle = (index) => {
    setIsOpen2(isOpen === index ? null : index);
  };

  const faqData = [
    {
      question: 'What we do.',
      answer: 'We connect buyers with sellers, negotiate deals, handle paperwork, and ensure smooth transactions so that you can rest at home knowing that everything is alright.'
    },
    {
      question: 'How do I buy a yacht through a brokerage?',
      answer: 'Buying a yacht through a brokerage is simple. You can browse our listings online or contact us directly with your specifications. We’ll guide you through the process, from finding the right yacht to closing the deal.'
    },
    {
      question: 'What types of yachts do you sell?',
      answer: 'We offer a wide range of yachts, including motor yachts, sailing yachts and mega yachts Whether you’re looking for a sleek cruiser or a spacious superyacht, we have options to suit every taste and budget.'
    },
    {
      question:'Do you assist with yacht financing and insurance?',
      answer:'Yes, we can help you secure financing and insurance for your yacht purchase. We work with trusted lenders and insurance providers to ensure you get the best rates and coverage options. '
    }
    ,
    {
      question:'How are yacht prices determined?',
      answer:'Yacht prices vary depending on factors such as size, age, condition, brand, and features. Our experienced brokers can provide you with market insights and help you negotiate the best price for your desired yacht.'
    },
    {
      question:'Can I sell my yacht through your brokerage?',
      answer:'Absolutely! We welcome yacht owners looking to sell their vessels. Our team will work with you to market your yacht effectively, attract qualified buyers, and secure the best possible sale price. Give us a call or drop us a email if you are interested in listing a Yacht with us!.',
    },
    {
      question:'Why should I choose your brokerage for my yacht needs?',
      answer:'With industry low commissions, personalized service, and commitment to client satisfaction. Whether you’re buying, selling a yacht, you can trust us to deliver exceptional results and exceed your expectations every step of the way.',
    }
    // Add more FAQs as needed
  ];

  const width = useWindowWidth();
  const isMobile = width < 768;


  return (
    <div className="App">
      
        <nav className="initial-opacity flex items-center justify-between flex-wrap p-6 z-20 fixed top-0 left-0 right-0  bg-opacity-50 backdrop-blur-md shadow-bottom-stroke opacity-1 fade-in-down">
              <div className="flex items-center flex-shrink-0 text-em-brown mr-6 lg:mr-40">
                <a href="/" className="font-sans text-3xl font-bold tracking-extra-wide">
                <img src={logo} alt="Elite Marine Logo" className="w-10"></img>
                </a>
              </div>
              <div className="block lg:hidden">
                <button
                  onClick={() => setIsOpen(!isOpen)}
                  className="flex items-center px-3 py-2 rounded text-black-500 hover:text-black-400"
                >
                  <svg
                    className={`fill-current h-3 w-3 ${isOpen ? 'hidden' : 'block'}`}
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                  </svg>
                  <svg
                    className={`fill-current h-3 w-3 ${isOpen ? 'block' : 'hidden'}`}
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                  <path d="M12 19V5m-7 7l7-7 7 7" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </button>
              </div>
              <div
                className={`w-full block flex-grow lg:flex lg:items-center lg:w-auto ${isOpen ? 'block' : 'hidden'}`}
              >
                <div className="text-1.5xl lg:flex-grow">
                <a href="/" className="block mt-4 lg:inline-block lg:mt-0 text-em-brown font-sans mx-2  hover:text-em-select cursor-pointer">
                  HOME
                </a>
                <a href="/Charter" className="block mt-4 lg:inline-block lg:mt-0 text-em-brown mx-2 font-sans hover:text-em-select cursor-pointer">
                  CHARTER
                </a>
                <a href="/Listings" className="block mt-4 lg:inline-block lg:mt-0 text-em-brown mx-2 font-sans hover:text-em-select cursor-pointer">
                  BROKERAGE
                </a>
                <a href="/About" className="block mt-4 lg:inline-block lg:mt-0 text-em-brown mx-2 font-sans hover:text-em-select cursor-pointer">
                  ABOUT
                </a>
                <a href="/FAQ" className="block mt-4 lg:inline-block lg:mt-0 text-em-brown mx-2 font-sans hover:text-em-select cursor-pointer">
                  FAQ
                </a>

                </div>
                <div>
                <a href="/Enquire">
                <button className="inline-flex text-1.5xl items-center  border-em-brown py-1 px-10 b text-em-brown underline">
                  ENQUIRE
                </button>
                </a>

                </div>
              </div>
            </nav>



      <div className="segment-1"> {/* Landing Page Header */}
        <div className="segment-1-body">
            
          <div className="main-header">

            { isMobile ? (
              <h1 className="text-em-brown mb-8 text-xl font-sans tracking-extra-wide leading-none initial-opacity scale-in
              ">THE BEST YACHTS MADE <br></br>EXCLUVSIVE FOR YOU</h1>
            ) : (
              <h1 className="text-em-brown mb-16 text-6xl font-sans tracking-extra-wide leading-none initial-opacity scale-in
              ">THE BEST YACHTS MADE <br></br>EXCLUVSIVE FOR YOU</h1>
            )}


          </div>
          <div className="buy-charter-button flex space-x-0 initial-opacity fade-in-up">
            <a href="listings">
            <button className="text-2xl buy-button border-t-2 border-l-2 border-b-2 border-r-2 border-em-brown py-2 px-4 text-em-brown bg-transparent  hover:bg-em-brown hover:text-white transition w-52">
              Buy
            </button>
            </a>
            <a href="charter">
            <button className="text-2xl charter-button border-t-2 border-r-2 border-b-2 border-r-2 border-em-brown py-2 px-4 text-em-brown bg-transparent  hover:bg-em-brown hover:text-white transition w-52">
              Charter
            </button>
            </a>
          </div>


        </div>
      </div>

      <div className="segment-2"> {/* Who We Are */}
        <div className="segment-2-body pt-auto">

              {isMobile ? (
                              <div className="bg-white w-1/5 pt-11 pl-6 pr-6  max-h-82 min-w-80 ml-auto mr-auto">
                              <h2 className="font-sans text-4xl">WHO WE ARE</h2>
                              <p className="text-ellipsis text-xl text-justify">
                                With years of industry experience, we bring unparalleled expertise and dedication to the table. Our team of dedicated marketing experts and seasoned brokers is committed to delivering the highest level of service and care. Discover more about our exceptional team and how we can serve you below.
                              </p>
                              <button className="w-full bg-black text-white mt-12 py-2 text-xl mb-10">
                                ENQUIRE
                              </button>
                            </div>
              ) : (
                              <div className="bg-white w-1/5 pt-11 pl-6 pr-6 ml-[18%] max-h-82 min-w-72">
                              <h2 className="font-sans text-4xl">WHO WE ARE</h2>
                              <p className="text-ellipsis text-xl text-justify">
                                With years of industry experience, we bring unparalleled expertise and dedication to the table. Our team of dedicated marketing experts and seasoned brokers is committed to delivering the highest level of service and care. Discover more about our exceptional team and how we can serve you below.
                              </p>
                              <button className="w-full bg-black text-white mt-12 py-2 text-xl mb-10">
                                ENQUIRE
                              </button>
                            </div>
              )}

        </div>
      </div>


      <div className="segment-3 bg-em-skin mt-6"> {/* Be Inspired */}
        <div className ="segment-3-body h-[100%]">
            <h1 className="font-sans text-6xl pt-32 mb-20">BE INSPIRED</h1>

            { isMobile ? (
              <div
              ref={carouselRef}
              className="cards-container overflow-x-auto flex md:justify-center space-x-4 px-4 snap-x snap-mandatory scroll-smooth"
              onMouseEnter={() => setIsPaused(true)}
              onMouseLeave={() => setIsPaused(false)}
              onTouchStart={() => setIsPaused(true)}
              onTouchEnd={() => setIsPaused(false)}
            >
              <div className="card bg-white h-[700px] min-w-80 flex flex-col justify-between p-4 snap-center">
                <div>
                <div class="overflow-hidden h-[600px] w-full flex justify-center items-center">
                  <img src={searay} alt="Searay Sundancer 470" class="object-cover h-full w-full" />
                </div>
                  <h1 className="font-sans text-2xl">SEARAY SUNDANCER 470</h1>
                  <a href="charter">
                    <button className="text-xl underline tracking-extra-wide text-em-brown">VIEW MORE</button>
                  </a>
                </div>
              </div>
        
              <div className="card bg-white h-[700px] min-w-80 flex flex-col justify-between p-4 snap-center">
         
                <div>
                <div class="overflow-hidden h-[600px] w-full flex justify-center items-center">
                  <img src={lagoon} alt="lagoon" class="object-cover h-full w-full" />
                </div>
                  <h1 className="font-sans text-2xl">LAGOON 51</h1>
                  <a href="charter">
                    <button className="text-xl underline tracking-extra-wide text-em-brown">VIEW MORE</button>
                  </a>
                </div>
              </div>
        
              <div className="card bg-white h-[700px] min-w-80 flex flex-col justify-between p-4 snap-center">
              <div class="overflow-hidden h-[600px] w-full flex justify-center items-center">
                  <img src={monterey} alt="montery" class="object-cover h-full w-full" />
                </div>
                <div>
                  <h1 className="font-sans text-2xl">MONTEREY 275 5CR</h1>
                  <a href="charter">
                    <button className="text-xl underline tracking-extra-wide text-em-brown">VIEW MORE</button>
                  </a>
                </div>
              </div>
            </div>
             ) : (
            
              <div class="cards flex justify-center space-x-10 ml-auto mr-auto">
                <div class="cards-1 bg-white h-[700px] min-w-96 flex flex-col justify-between p-4 mb-56 card card-1">
                <div class="overflow-hidden h-full w-full flex justify-center items-center">
                  <img src={searay} alt="Searay Sundancer 470" class="object-cover h-full w-full" />
                </div>
                  <div></div> <div><h1 class="font-sans text-2xl">SEARAY SUNDANCER 470</h1><a href="charter">
                    <button class="text-xl underline tracking-extra-wide text-em-brown">VIEW MORE</button>
                    </a></div></div>
                    
                    <div class="cards-2 bg-white h-[700px] min-w-96 flex flex-col justify-between p-4 card card-2">
                    <div class="overflow-hidden h-full w-full flex justify-center items-center">
                  <img src={lagoon} alt="lagoon" class="object-cover h-full w-full" />
                </div>
                      <div><h1 class="font-sans text-2xl">LAGOON 51</h1>
                      <a href="charter"><button class="text-xl underline tracking-extra-wide text-em-brown">VIEW MORE</button></a></div></div>
                      <div class="cards-3 bg-white h-[700px] min-w-96 flex flex-col justify-between p-4 card card-3">
                      <div class="overflow-hidden h-full w-full flex justify-center items-center">
                        <img src={monterey} alt="Searay Sundancer 470" class="object-cover h-full w-full" />
                      </div>
                         <div><h1 class="font-sans text-2xl">MONTEREY 275 5CR</h1><a href="charter"><button class="text-xl underline tracking-extra-wide text-em-brown">VIEW MORE</button></a></div></div></div>
             )}


          </div>
          </div>

      <div className="segment-4 bg-em-skin py-10 mb-80 font-sans mt-72">
        <div className="segment-4-body">
        <div className="container mx-auto px-6 md:px-12 mb-32">
          <h1 className="text-3xl text-center mb-8">FREQUENTLY ASKED QUESTIONS</h1>
          <div className="space-y-4 text-center mx-auto w-full">
            {faqData.map((item, index) => (
              <div key={index} className="bg-white overflow-hidden mx-auto max-w-[78rem]"> {/* Set width to match cards */}
                <button
                  onClick={() => toggle(index)}
                  className="w-full text-left px-6 py-4 flex justify-between items-center bg-white-200 hover:bg-gray-300 focus:outline-none"
                >
                  <span className="text-xl">{item.question}</span>
                  <svg
                    className={`w-6 h-6 transform transition-transform duration-300 ${isOpen2 === index ? 'rotate-180' : 'rotate-0'}`}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>
                {isOpen2 === index && (
                  <div className="p-6 text-gray-700">
                    <p>{item.answer}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        </div>
      </div>





      <div className="segment-5 bg-em-brown text-white py-10">
        <div className="container mx-auto px-6 md:px-12 lg:px-20">
            <div className="flex flex-wrap justify-between font-sans">
                
                      <div className="logo mb-8 w-full md:w-1/4">
                        {/* <img src="path-to-logo.png" alt="Elite Marine Logo" className="w-40 mb-4"> */}
                        <p className="text-sm leading-tight">Luxury Yachts & Marine Services</p>
                      </div>

  
                      <div className="quick-links mb-8 w-full md:w-1/4">
                        <h2 className="font-bold text-lg mb-4">Quick Links</h2>
                        <ul className="space-y-2">
                          <li><a href="#" className="hover:text-gray-400">Home</a></li>
                          <li><a href="#" className="hover:text-gray-400">Charter</a></li>
                          <li><a href="#" className="hover:text-gray-400">Brokerage</a></li>
                          <li><a href="#" className="hover:text-gray-400">Sell</a></li>
                          <li><a href="#" className="hover:text-gray-400">About</a></li>
                          <li><a href="#" className="hover:text-gray-400">FAQ</a></li>
                        </ul>
                      </div>

       
                      <div className="singapore mb-8 w-full md:w-1/4">
                        <h2 className=" font-bold text-lg mb-4">Singapore Office</h2>
                        <p className="text-sm">
                          <a href="mailto:info@elite-marine.net" className="hover:text-gray-400">info@elite-marine.net</a><br></br>
                          <a href="tel:+6580536058" className="hover:text-gray-400">+65 8053 6058</a><br></br>
                          <a href="tel:+6583743641" className="hover:text-gray-400">+65 8374 3641</a><br></br>
                          29 Benoi Road, Pioneer Lot, <br></br> Singapore 629922
                        </p>
                      </div>

        
                      <div className="italy mb-8 w-full md:w-1/4">
                        <h2 className="font-bold text-lg mb-4">Italy Office</h2>
                        <p className="text-sm">
                          <a href="mailto:info@elite-marine.net" className="hover:text-gray-400">info@elite-marine.net</a><br></br>
                          <a href="tel:+393514968131" className="hover:text-gray-400">+39 351 496 8131</a><br></br>
                          Via del Parco Margherita 24, <br></br> 80121 Napoli NA, Italy
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="text-center mt-8">
                    <p className="text-sm">&copy; 2024 Elite Marine. All rights reserved.</p>
                  </div>
            
        
      </div>


          
          

    </div>
  );
}

export default HomePage;
