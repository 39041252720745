import React from 'react';
import logo from '../images/EMLOGO.png';


function About() {
    const [isOpen, setIsOpen] = React.useState(false);

    return (
        <div className="">
            {/* Navbar */}
            <nav className="opacity-1 flex items-center justify-between flex-wrap p-6 z-20 fixed top-0 left-0 right-0 bg-opacity-50 backdrop-blur-md shadow-bottom-stroke">
                <div className="flex items-center flex-shrink-0 text-em-brown mr-6 lg:mr-8">
                <a href="/" className="font-sans text-3xl font-bold tracking-extra-wide">
                <img src={logo} alt="Elite Marine Logo" className="w-10"></img>
                </a>
                </div>
                <div className="block lg:hidden">
                    <button
                        onClick={() => setIsOpen(!isOpen)}
                        className="flex items-center px-3 py-2 rounded text-black-500 hover:text-black-400"
                    >
                        <svg
                            className={`fill-current h-3 w-3 ${isOpen ? 'hidden' : 'block'}`}
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                        </svg>
                        <svg
                            className={`fill-current h-3 w-3 ${isOpen ? 'block' : 'hidden'}`}
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071-7.071-1.414-1.414L10 8.586z" />
                        </svg>
                    </button>
                </div>
                <div className={`w-full block flex-grow lg:flex lg:items-center lg:w-auto ${isOpen ? 'block' : 'hidden'}`}>
                    <div className="text-1.5xl lg:flex-grow">
                        <a href="/" className="block mt-4 lg:inline-block lg:mt-0 text-em-brown mr-4 font-sans hover:text-em-select cursor-pointer">
                            HOME
                        </a>
                        <a href="/Charter" className="block mt-4 lg:inline-block lg:mt-0 text-em-brown mr-4 font-sans hover:text-em-select cursor-select">
                            CHARTER
                        </a>
                        <a href="/Listings" className="block mt-4 lg:inline-block lg:mt-0 text-em-brown mr-4 font-sans hover:text-em-select cursor-select">
                            BROKERAGE
                        </a>
                        <a href="/about" className="block mt-4 lg:inline-block lg:mt-0 text-em-brown mr-4 font-sans hover:text-em-select cursor-select">
                            ABOUT
                        </a>
                        <a href="/faq" className="block mt-4 lg:inline-block lg:mt-0 text-em-brown mr-4 font-sans hover:text-em-select cursor-select">
                            FAQ
                        </a>
                    </div>
                    <a href="/enquire">
                    <div>
                        <button className="inline-flex text-1.5xl items-center border-em-brown py-1 px-10 b text-em-brown underline">ENQUIRE</button>
                    </div>
                    </a>
                </div>
            </nav>

            {/* About Us Section */}
            <section className="max-w-4xl mx-auto mt-32 p-8 bg-white rounded-md shadow-lg">
                <h1 className="text-4xl font-bold text-center mb-8 text-em-brown">About Us</h1>
                <p className="text-lg text-gray-700 mb-8 leading-relaxed">
                    At <span className="font-semibold">Elite Marine</span>, we specialize in providing unparalleled luxury yachts and marine services to clients worldwide. 
                    With offices in both Singapore and Italy, we are committed to delivering personalized experiences tailored to the needs of yacht owners, buyers, and charterers.
                </p>

                <div className="flex flex-wrap justify-between">
                    {/* Mission Section */}
                    <div className="w-full md:w-1/2 p-4">
                        <h2 className="text-2xl font-semibold mb-4 text-em-brown">Our Mission</h2>
                        <p className="text-gray-700 leading-relaxed">
                            Our mission is to connect people with their dream yachts while ensuring top-notch service and support throughout the journey. Whether you're 
                            buying, selling, or chartering a yacht, we aim to make your experience seamless and enjoyable.
                        </p>
                    </div>

                    {/* Vision Section */}
                    <div className="w-full md:w-1/2 p-4">
                        <h2 className="text-2xl font-semibold mb-4 text-em-brown">Our Vision</h2>
                        <p className="text-gray-700 leading-relaxed">
                            We envision a world where luxury yachting is accessible, sustainable, and unforgettable. Our goal is to become a leader in the marine industry 
                            by fostering innovation, sustainability, and unmatched customer service.
                        </p>
                    </div>
                </div>

                {/* Offices Section */}
                <div className="mt-16">
                    <h2 className="text-4xl font-semibold mb-8 text-em-brown text-center">Our Global Presence</h2>
                    <div className="flex flex-wrap">
                        <div className="w-full md:w-1/2 p-4">
                            <h3 className="text-xl font-semibold text-em-brown mb-2">Singapore Office</h3>
                            <p className="text-gray-700">
                                <a href="mailto:info@elite-marine.net" className="hover:text-gray-400">info@elite-marine.net</a><br />
                                <a href="tel:+6580536058" className="hover:text-gray-400">+65 8053 6058</a><br />
                                29 Benoi Road, Pioneer Lot, Singapore 629922
                            </p>
                        </div>
                        <div className="w-full md:w-1/2 p-4">
                            <h3 className="text-xl font-semibold text-em-brown mb-2">Italy Office</h3>
                            <p className="text-gray-700">
                                <a href="mailto:info@elite-marine.net" className="hover:text-gray-400">info@elite-marine.net</a><br />
                                <a href="tel:+393514968131" className="hover:text-gray-400">+39 351 496 8131</a><br />
                                Via del Parco Margherita 24, 80121 Napoli, Italy
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Footer */}
            <div className="segment-5 bg-em-brown text-white py-10 mt-10">
        <div className="container mx-auto px-6 md:px-12 lg:px-20">
            <div className="flex flex-wrap justify-between font-sans">
                
                      <div className="logo mb-8 w-full md:w-1/4">
                        {/* <img src="path-to-logo.png" alt="Elite Marine Logo" className="w-40 mb-4"> */}
                        <p className="text-sm leading-tight">Luxury Yachts & Marine Services</p>
                      </div>

  
                      <div className="quick-links mb-8 w-full md:w-1/4">
                        <h2 className="font-bold text-lg mb-4">Quick Links</h2>
                        <ul className="space-y-2">
                          <li><a href="#" className="hover:text-gray-400">Home</a></li>
                          <li><a href="#" className="hover:text-gray-400">Charter</a></li>
                          <li><a href="#" className="hover:text-gray-400">Brokerage</a></li>
                          <li><a href="#" className="hover:text-gray-400">Sell</a></li>
                          <li><a href="#" className="hover:text-gray-400">About</a></li>
                          <li><a href="#" className="hover:text-gray-400">FAQ</a></li>
                        </ul>
                      </div>

       
                      <div className="singapore mb-8 w-full md:w-1/4">
                        <h2 className=" font-bold text-lg mb-4">Singapore Office</h2>
                        <p className="text-sm">
                          <a href="mailto:info@elite-marine.net" className="hover:text-gray-400">info@elite-marine.net</a><br></br>
                          <a href="tel:+6580536058" className="hover:text-gray-400">+65 8053 6058</a><br></br>
                          <a href="tel:+6583743641" className="hover:text-gray-400">+65 8374 3641</a><br></br>
                          29 Benoi Road, Pioneer Lot, <br></br> Singapore 629922
                        </p>
                      </div>

        
                      <div className="italy mb-8 w-full md:w-1/4">
                        <h2 className="font-bold text-lg mb-4">Italy Office</h2>
                        <p className="text-sm">
                          <a href="mailto:info@elite-marine.net" className="hover:text-gray-400">info@elite-marine.net</a><br></br>
                          <a href="tel:+393514968131" className="hover:text-gray-400">+39 351 496 8131</a><br></br>
                          Via del Parco Margherita 24, <br></br> 80121 Napoli NA, Italy
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="text-center mt-8">
                    <p className="text-sm">&copy; 2024 Elite Marine. All rights reserved.</p>
                  </div>
            
        
            </div>

        </div>
    );
}

export default About;
