import React, { useState, useEffect } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import logo from "../images/EMLOGO.png";

const Listing = () => {
    const location = useLocation();
    const getQueryParam = (param) => {
        const params = new URLSearchParams(location.search);
        return params.get(param);
    };

    const uid = getQueryParam('uid');
    const service = getQueryParam('service');
    const buttonDisplay = service === 'charter' ? 'CHARTER' : 'ENQUIRE';

    const [charterFromDate, setCharterFromDate] = useState(null);
    const [charterToDate, setCharterToDate] = useState(null);
    const [selectedPicture, setSelectedPicture] = useState(null);
    const [yachtStats, setYachtStats] = useState({});
    const [imageUrls, setImageUrls] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEnquireModalOpen, setIsEnquireModalOpen] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [selectedDate, setSelectedDate] = useState(null);

    const handlePictureSelect = (picture) => setSelectedPicture(picture);
    const handleCharterClick = () => service === 'charter' && setIsModalOpen(true);
    const handleEnquireClick = () => service !== 'charter' && setIsEnquireModalOpen(true);



    const handleFormSubmit = async (e) => {

        var adminText = "";
        var adminSubject = "";
        e.preventDefault();

        if (!charterFromDate || !charterToDate) {
            adminText = "General Enquiry on " + yachtStats.name + " from " + name + " at " + email;
            adminSubject = "General Enquiry on " + yachtStats.name;
        } else {
            adminText = "Charter Enquiry on : " + yachtStats.name + " from " + name + " at " + email + " for the dates " + charterFromDate + " to " + charterToDate;
            adminSubject = "Charter Enquiry on : " + yachtStats.name;
        }
    
        try {
            const response = await axios.post(
                'https://us-central1-elite-marine-web-development.cloudfunctions.net/api/sendEmail',
                {
                    name: name,
                    to: email,
                    subject: adminSubject,
                    text: adminText,

                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
    
            if (response.status === 200) {
                alert('Thank you for your enquiry! We will get back to you soon.');

            } else {
                alert(`Error: ${response.statusText}`);
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Failed to send enquiry. Please try again later.');
        }
    };


    const handleEnquireFormSubmit = async (e) => {
        e.preventDefault();
    
        try {
            const response = await axios.post(
                'https://us-central1-elite-marine-web-development.cloudfunctions.net/api/sendEmail',
                {
                    name: name,
                    to: email,
                    subject: "Charter Enquiry on : " + yachtStats.name,
                    text: "General Enquiry on : " + yachtStats.name + " from " + name + " at " + email,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
    
            if (response.status === 200) {
                alert('Thank you for your enquiry! We will get back to you soon.');

            } else {
                alert(`Error: ${response.statusText}`);
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Failed to send enquiry. Please try again later.');
        }
    };

    const fetchYachtData = async () => {
        try {
            const folderPath = `${uid}`;
            const imagesResponse = await axios.get('https://us-central1-elite-marine-web-development.cloudfunctions.net/api/getImages', {
                params: { folderPath }
            });
            const infoResponse = await axios.get('https://us-central1-elite-marine-web-development.cloudfunctions.net/api/getYacht', { params: { uid } });

            setYachtStats(infoResponse.data);
            setImageUrls(imagesResponse.data);
        } catch (error) {
            console.error('Error fetching yacht data:', error);
        }
    };

    useEffect(() => {
        fetchYachtData();
    }, []);

    return (
        <div className="fixed listings-body h-full bg-em-skin overflow-y-auto">
            <nav className="opacity-1 flex items-center justify-between flex-wrap p-6 z-20 fixed top-0 left-0 right-0 bg-opacity-50 backdrop-blur-md shadow-bottom-stroke">
                <div className="flex items-center flex-shrink-0 text-em-brown mr-6 lg:mr-8">
                <a href="/" className="font-sans text-3xl font-bold tracking-extra-wide">
                <img src={logo} alt="Elite Marine Logo" className="w-10"></img>
                </a>
                </div>
                <div className="block lg:hidden">
                    <button
                        onClick={() => setIsOpen(!isOpen)}
                        className="flex items-center px-3 py-2 rounded text-black-500 hover:text-black-400"
                    >
                        <svg
                            className={`fill-current h-3 w-3 ${isOpen ? 'hidden' : 'block'}`}
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                        </svg>
                        <svg
                            className={`fill-current h-3 w-3 ${isOpen ? 'block' : 'hidden'}`}
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071-7.071-1.414-1.414L10 8.586z" />
                        </svg>
                    </button>
                </div>
                <div className={`w-full block flex-grow lg:flex lg:items-center lg:w-auto ${isOpen ? 'block' : 'hidden'}`}>
                    <div className="text-1.5xl lg:flex-grow">
                        <a href="/" className="block mt-4 lg:inline-block lg:mt-0 text-em-brown mr-4 font-sans hover:text-em-select cursor-pointer">
                            HOME
                        </a>
                        <a href="/Charter" className="block mt-4 lg:inline-block lg:mt-0 text-em-brown mr-4 font-sans hover:text-em-select cursor-select">
                            CHARTER
                        </a>
                        <a href="/Listings" className="block mt-4 lg:inline-block lg:mt-0 text-em-brown mr-4 font-sans hover:text-em-select cursor-select">
                            BROKERAGE
                        </a>
                        <a href="/faq" className="block mt-4 lg:inline-block lg:mt-0 text-em-brown mr-4 font-sans hover:text-em-select cursor-select">
                            ABOUT
                        </a>
                        <a href="/faq" className="block mt-4 lg:inline-block lg:mt-0 text-em-brown mr-4 font-sans hover:text-em-select cursor-select">
                            FAQ
                        </a>
                    </div>
                    <a>
                    <div>
                        <button className="inline-flex text-1.5xl items-center border-em-brown py-1 b text-em-brown underline">ENQUIRE</button>
                    </div>
                    </a>
                </div>
            </nav>

            <div className="flex flex-col md:flex-row mx-auto pt-20 md:pt-0 mt-40">
                <div className="w-full md:w-3/5 mx-auto p-4">
                    <Carousel autoPlay interval="5000" transitionTime="1000"  infiniteLoop>
                        {imageUrls.length > 0 ? (
                            imageUrls.map((url, index) => (
                                <div key={index}>
                                    <img src={url} alt={`Yacht Image ${index + 1}`} />
                                </div>
                            ))
                        ) : (
                            <p>Loading images...</p>
                        )}
                    </Carousel>
                </div>

                <div className="w-full md:w-1/4  mt-8 md:mt-0 mx-auto">
                    <div className="yacht-stats font-sans text-center md:text-left">
                        <h2 className="text-3xl md:text-5xl font-semibold mb-2">{yachtStats.name || 'YACHT NAME'}</h2>
                        <hr className="border-b-2 border-black mb-4" />
                        {
                            yachtStats.price !== "On Request" ? (
                                <>
                                    <p className="text-3xl md:text-5xl font-semibold">
                                        €{yachtStats.price || 'XXX,XXX'} <span className="text-sm">({yachtStats.price_us}/month)</span>
                                    </p>
                                    <p className="text-sm mb-4">OWN THIS BOAT FOR {yachtStats.price_monthly} A MONTH</p>
                                </>
                            ) : <>                                    <p className="text-3xl md:text-5xl font-semibold">
                            On Request
                        </p> </>
                        }

                        <div className="grid grid-cols-2 gap-2 mb-4 mt-14 text-lg">
                            <div>Engine</div>
                            <div className="text-right">Total power</div>
                            <div className="font-semibold">{yachtStats.engine || 'XXXXX'}</div>
                            <div className="text-right font-semibold">{yachtStats.total_power || 'XXXX'}</div>
                        </div>

                        <div className="grid grid-cols-2 gap-2 mb-4">
                            <div>Length</div>
                            <div className="text-right">Year</div>
                            <div className="font-semibold">{yachtStats.length || 'XXXXX'}</div>
                            <div className="text-right font-semibold">{yachtStats.year || 'XXXX'}</div>
                        </div>


                        <div className="grid grid-cols-2 gap-2 mb-6">
                            <div>Model</div>
                            <div className="text-right">Capacity</div>
                            <div className="font-semibold">{yachtStats.model || 'XXXXX'}</div>
                            <div className="text-right font-semibold">{yachtStats.capacity || 'XXXX'}</div>
                        </div>


                        <div className="about-info h-auto mb-24">
                            <h3 className="text-lg font-bold mb-2">Description</h3>
                            <hr className="border-b-2 border-black mb-4" />
                            <p className="text-m text-justify mb-4">
                                {yachtStats.description || 'This yacht is a luxurious vessel designed for comfort and performance...'}
                            </p>
                            <button
                                onClick={service === 'charter' ? handleCharterClick : handleEnquireClick}
                                className="bg-black text-white h-12 w-full font-bold"
                            >
                                {buttonDisplay}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* Modals and Footer */}
            {isModalOpen && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
                <div className="bg-white p-8 rounded-md shadow-lg max-w-lg w-full relative">
                    <h2 className="text-xl font-bold mb-4">Your information</h2>
                    
                    {/* Close button */}
                    <button
                        onClick={() => setIsModalOpen(false)}
                        className="absolute top-2 right-3 text-gray-700 hover:text-gray-900 "
                    >
                        X
                    </button>
                    
                    <form onSubmit={handleFormSubmit}>
                        <div className="mb-4">
                            <label className="block text-gray-700">Name</label>
                            <input
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700">Email</label>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700">Charter From</label>
                            <DatePicker
                                selected={charterFromDate}
                                onChange={(date) => setCharterFromDate(date)}
                                dateFormat="yyyy/MM/dd"
                                minDate={new Date()}
                                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring"
                                placeholderText="Select start date"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700">Charter To</label>
                            <DatePicker
                                selected={charterToDate}
                                onChange={(date) => setCharterToDate(date)}
                                dateFormat="yyyy/MM/dd"
                                minDate={charterFromDate || new Date()}  // Ensure "To" date is after "From" date
                                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring"
                                placeholderText="Select end date"
                            />
                        </div>
                        <div className="flex justify-end">
                            <button
                                type="submit"
                                className="bg-black text-white font-bold py-2 px-4 rounded hover:bg-black-600"
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            
            
            )}

            {isEnquireModalOpen && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
                    <div className="bg-white p-8 rounded-md shadow-lg max-w-lg w-full relative">
                        {/* Close Button in the top-right corner of the white box */}
                        <button 
                            onClick={() => setIsEnquireModalOpen(false)} 
                            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                        >
                            X
                        </button>

                        {/* Form Title */}
                        <h2 className="text-2xl font-bold mb-6 text-center">Enquire</h2>

                        {/* Enquire Form */}
                        <form onSubmit={handleEnquireFormSubmit} className="space-y-6">
                            <div>
                                <label className="block text-gray-700 mb-2">Name</label>
                                <input
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    placeholder="Enter your name"
                                    required
                                    className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring"
                                />
                            </div>

                            <div>
                                <label className="block text-gray-700 mb-2">Email</label>
                                <input
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Enter your email"
                                    required
                                    className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring"
                                />
                            </div>

                            <div className="flex justify-end">
                                <button
                                    type="submit"
                                    className="bg-black text-white font-bold py-2 px-4 rounded hover:bg-gray-800"
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            <div className="segment-5 bg-em-brown text-white py-10">
                                        <div className="container mx-auto px-6 md:px-12 lg:px-20">
                                            <div className="flex flex-wrap justify-between font-sans">
                                                
                                        <div className="logo mb-8 w-full md:w-1/4">
                                            {/* <img src="path-to-logo.png" alt="Elite Marine Logo" className="w-40 mb-4"> */}
                                            <p className="text-sm leading-tight">Luxury Yachts & Marine Services</p>
                                        </div>

                    
                                        <div className="quick-links mb-8 w-full md:w-1/4">
                                            <h2 className="font-bold text-lg mb-4">Quick Links</h2>
                                            <ul className="space-y-2">
                                            <li><a href="#" className="hover:text-gray-400">Home</a></li>
                                            <li><a href="#" className="hover:text-gray-400">Charter</a></li>
                                            <li><a href="#" className="hover:text-gray-400">Brokerage</a></li>
                                            <li><a href="#" className="hover:text-gray-400">Sell</a></li>
                                            <li><a href="#" className="hover:text-gray-400">About</a></li>
                                            <li><a href="#" className="hover:text-gray-400">FAQ</a></li>
                                            </ul>
                                        </div>

                        
                                        <div className="singapore mb-8 w-full md:w-1/4">
                                            <h2 className=" font-bold text-lg mb-4">Singapore Office</h2>
                                            <p className="text-sm">
                                            <a href="mailto:info@elite-marine.net" className="hover:text-gray-400">info@elite-marine.net</a><br></br>
                                            <a href="tel:+6580536058" className="hover:text-gray-400">+65 8053 6058</a><br></br>
                                            <a href="tel:+6583743641" className="hover:text-gray-400">+65 8374 3641</a><br></br>
                                            29 Benoi Road, Pioneer Lot, <br></br> Singapore 629922
                                            </p>
                                        </div>

                            
                                        <div className="italy mb-8 w-full md:w-1/4">
                                            <h2 className="font-bold text-lg mb-4">Italy Office</h2>
                                            <p className="text-sm">
                                            <a href="mailto:info@elite-marine.net" className="hover:text-gray-400">info@elite-marine.net</a><br></br>
                                            <a href="tel:+393514968131" className="hover:text-gray-400">+39 351 496 8131</a><br></br>
                                            Via del Parco Margherita 24, <br></br> 80121 Napoli NA, Italy
                                            </p>
                                        </div>
                                        </div>
                                    </div>

                                    <div className="text-center mt-8">
                                        <p className="text-sm">&copy; 2024 Elite Marine. All rights reserved.</p>
                                    </div>
                                
                            
            </div>
        </div>
    );
};

export default Listing;
