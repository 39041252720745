import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import Listings from './pages/Listings';
import Listing from './pages/Listing';
import Charter from './pages/Charter';
import Enquire from './pages/Enquire';
import About from './pages/About';
import FAQ from './pages/FAQ';

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/About" element={<About />} />
        <Route path="/FAQ" element={<FAQ />} />
        <Route path="/Listings" element={<Listings />} />
        <Route path="/Listing" element={<Listing />} />
        <Route path="/Charter" element={<Charter />} />
        <Route path=  "/Enquire" element={<Enquire />} />
      </Routes>
    </div>
  );
}

export default App;
