import React, { useState, useEffect } from 'react';
import axios from 'axios';
import logo from '../images/EMLOGO.png';

function Listings() {
  const [yachtListings, setListings] = useState([]);
  const [filteredYachts, setFilteredYachts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [selectedLength, setSelectedLength] = useState('All');
  const [selectedYear, setSelectedYear] = useState('All');
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const fetchListingsData = async () => {
    try {
      const response = await axios.get('https://us-central1-elite-marine-web-development.cloudfunctions.net/api/getListings');
      const listingsData = response.data;

      const formattedListings = await Promise.all(
        Object.keys(listingsData).map(async (key) => {
          const yacht = listingsData[key];

          console.log(key)
          var imageResponse = await axios.get('https://us-central1-elite-marine-web-development.cloudfunctions.net/api/getImages', {
            params: { folderPath: key },
          });

          console.log("imageResponse", imageResponse)


        
          const imageUrl = imageResponse.data[imageResponse.data.length - 1];


          return {
            id: key,
            name: yacht.name,
            price: yacht.price,
            length: yacht.length,
            year: yacht.year,
            category: yacht.category,
            imageUrl,
          };
        })
      );  

      console.log('Formatted Listings:', formattedListings);

      setListings(formattedListings);
      setFilteredYachts(formattedListings); // Initialize filtered yachts
    } catch (error) {
      console.error('Error fetching yacht data:', error);
    }
  };

  useEffect(() => {
    fetchListingsData();
  }, []);

  const handleSearch = () => {
    const filtered = yachtListings.filter((yacht) => {
      const matchesCategory = selectedCategory === 'All' || yacht.category === selectedCategory;
      const matchesLength = selectedLength === 'All' || yacht.length <= parseInt(selectedLength);
      const matchesYear = selectedYear === 'All' || yacht.year >= parseInt(selectedYear);
      const matchesPrice = yacht.price >= minPrice && (maxPrice === '' || yacht.price <= parseFloat(maxPrice));

      return matchesCategory && matchesLength && matchesYear && matchesPrice;
    });
    setFilteredYachts(filtered);
  };

  return (
    <div>
      {/* Navbar */}
      <nav className="flex items-center justify-between flex-wrap p-6 z-20 fixed top-0 left-0 right-0 bg-opacity-50 backdrop-blur-md shadow-bottom-stroke opacity-1">
      <div className="flex items-center flex-shrink-0 text-em-brown mr-6 lg:mr-8">
                <a href="/" className="font-sans text-3xl font-bold tracking-extra-wide">
                <img src={logo} alt="Elite Marine Logo" className="w-10"></img>
                </a>
                </div>
        <div className="block lg:hidden">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="flex items-center px-3 py-2 rounded text-black-500 hover:text-black-400"
          >
            <svg className={`fill-current h-3 w-3 ${isOpen ? 'hidden' : 'block'}`} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
            <svg className={`fill-current h-3 w-3 ${isOpen ? 'block' : 'hidden'}`} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
            </svg>
          </button>
        </div>
        <div className={`w-full block flex-grow lg:flex lg:items-center lg:w-auto ${isOpen ? 'block' : 'hidden'}`}>
          <div className="text-1.5xl lg:flex-grow">
            <a href="/" className="block mt-4 lg:inline-block lg:mt-0 text-em-brown mr-4 font-sans hover:text-em-select cursor-pointer">HOME</a>
            <a href="/Charter" className="block mt-4 lg:inline-block lg:mt-0 text-em-brown mr-4 font-sans hover:text-em-select cursor-pointer">CHARTER</a>
            <a href="/Listings" className="block mt-4 lg:inline-block lg:mt-0 text-em-brown mr-4 font-sans hover:text-em-select cursor-pointer">BROKERAGE</a>
            <a href="/about" className="block mt-4 lg:inline-block lg:mt-0 text-em-brown mr-4 font-sans hover:text-em-select cursor-pointer">ABOUT</a>
            <a href="/faq" className="block mt-4 lg:inline-block lg:mt-0 text-em-brown mr-4 font-sans hover:text-em-select cursor-pointer">FAQ</a>
          </div>
          <a href="/enquire">
          <div>
            <button className="inline-flex text-1.5xl items-center border-em-brown py-1 text-em-brown underline">ENQUIRE</button>
          </div>
          </a>
        </div>
      
      </nav>

      {/* Main Content */}
      <div className="relative container mx-auto max-w-[1920px] flex flex-col md:flex-row pl-[8%] pr-[8%] pt-[10%] pb-[10%]">
        {/* Filter Section */}
        <div className="md:hidden mb-4 mt-12">
          <button onClick={() => setIsFilterOpen(!isFilterOpen)} className="bg-em-brown text-white py-2 px-4 rounded w-full">
            {isFilterOpen ? 'Close Filters' : 'Open Filters'}
          </button>
        </div>

        <div className={`w-full md:w-1/4 lg:w-1/5 xl:w-1/4 p-4 mr-6 bg-white max-w-[600px] max-h-[450px] mb-4 ${isFilterOpen ? '' : 'hidden md:block'}`}>
          <div className="flex flex-col">
            {/* Category Filter */}
            <div className="mb-4">
              <label className="block text-gray-700 font-medium mb-2">Category</label>
              <select value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)} className="block w-full p-2 border border-gray-300 bg-slate-50 rounded">
                <option value="All">All</option>
                <option value="Power Boats">Power Boats</option>
                <option value="Sailboats">Sailboats</option>
              </select>
            </div>

            {/* Length Filter */}
            <div className="mb-4">
              <label className="block text-gray-700 font-medium mb-2">Length</label>
              <select value={selectedLength} onChange={(e) => setSelectedLength(e.target.value)} className="block w-full p-2 border border-gray-300 bg-slate-50 rounded">
                <option value="All">All</option>
                <option value="20">20 ft or less</option>
                <option value="30">30 ft or less</option>
                <option value="40">40 ft or less</option>
              </select>
            </div>

            {/* Year Filter */}
            <div className="mb-4">
              <label className="block text-gray-700 font-medium mb-2">Year</label>
              <select value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)} className="block w-full p-2 border border-gray-300 bg-slate-50 rounded">
                <option value="All">All</option>
                <option value="2000">2000 or newer</option>
                <option value="2010">2010 or newer</option>
                <option value="2020">2020 or newer</option>
              </select>
            </div>

            {/* Price Filter */}
            <div className="mb-4">
              <label className="block text-gray-700 font-medium mb-2">Price Range</label>
              <div className="flex">
                <input
                  type="number"
                  value={minPrice}
                  onChange={(e) => setMinPrice(e.target.value)}
                  className="block w-1/2 p-2 border border-gray-300 bg-slate-50 rounded mr-2"
                  placeholder="Min"
                />
                <input
                  type="number"
                  value={maxPrice}
                  onChange={(e) => setMaxPrice(e.target.value)}
                  className="block w-1/2 p-2 border border-gray-300 bg-slate-50 rounded"
                  placeholder="Max"
                />
              </div>
            </div>

            <button
              onClick={handleSearch}
              className="bg-black text-white py-2 px-4 rounded w-full"
            >
              Apply Filters
            </button>
          </div>
        </div>

        {/* Listings Section */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {filteredYachts.map((yacht) => (
          <div key={yacht.id} className="bg-white rounded-lg shadow-lg max-h-[450px] p-4 w-full max-w-sm mr-3 mb-4">
          <img src={yacht.imageUrl} alt={yacht.name} className="w-full object-cover rounded-md h-52" />
          <div className="mt-2 mb-2">
            <h3 className="text-lg font-bold">{yacht.name}</h3>
            <p className="text-sm">Price: ${yacht.price}</p>
            <p className="text-sm">Length: {yacht.length} ft</p>
            <p className="text-sm">Year: {yacht.year}</p>
            <p className="text-sm">Category: {yacht.category}</p>
          </div>
          <div className="flex justi  fy-end mt-4">
            <a
              href={`https://elite-marine-web-development.web.app/Listing?uid=${yacht.id}`}
              className="bg-black w-96 text-white text-center mt-4 py-2 px-4 rounded mb-2"
            >
              View
            </a>
          </div>
        </div>
        
        ))}
        </div>

      </div>

      <div className="segment-5 bg-em-brown text-white py-10">
        <div className="container mx-auto px-6 md:px-12 lg:px-20">
            <div className="flex flex-wrap justify-between font-sans">
                
                      <div className="logo mb-8 w-full md:w-1/4">
                        {/* <img src="path-to-logo.png" alt="Elite Marine Logo" className="w-40 mb-4"> */}
                        <p className="text-sm leading-tight">Luxury Yachts & Marine Services</p>
                      </div>

  
                      <div className="quick-links mb-8 w-full md:w-1/4">
                        <h2 className="font-bold text-lg mb-4">Quick Links</h2>
                        <ul className="space-y-2">
                          <li><a href="#" className="hover:text-gray-400">Home</a></li>
                          <li><a href="#" className="hover:text-gray-400">Charter</a></li>
                          <li><a href="#" className="hover:text-gray-400">Brokerage</a></li>
                          <li><a href="#" className="hover:text-gray-400">Sell</a></li>
                          <li><a href="#" className="hover:text-gray-400">About</a></li>
                          <li><a href="#" className="hover:text-gray-400">FAQ</a></li>
                        </ul>
                      </div>

       
                      <div className="singapore mb-8 w-full md:w-1/4">
                        <h2 className=" font-bold text-lg mb-4">Singapore Office</h2>
                        <p className="text-sm">
                          <a href="mailto:info@elite-marine.net" className="hover:text-gray-400">info@elite-marine.net</a><br></br>
                          <a href="tel:+6580536058" className="hover:text-gray-400">+65 8053 6058</a><br></br>
                          <a href="tel:+6583743641" className="hover:text-gray-400">+65 8374 3641</a><br></br>
                          29 Benoi Road, Pioneer Lot, <br></br> Singapore 629922
                        </p>
                      </div>

        
                      <div className="italy mb-8 w-full md:w-1/4">
                        <h2 className="font-bold text-lg mb-4">Italy Office</h2>
                        <p className="text-sm">
                          <a href="mailto:info@elite-marine.net" className="hover:text-gray-400">info@elite-marine.net</a><br></br>
                          <a href="tel:+393514968131" className="hover:text-gray-400">+39 351 496 8131</a><br></br>
                          Via del Parco Margherita 24, <br></br> 80121 Napoli NA, Italy
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="text-center mt-8">
                    <p className="text-sm">&copy; 2024 Elite Marine. All rights reserved.</p>
                  </div>
            
        
      </div>

    </div>
  );
}

export default Listings;
