import React, { useState, useEffect } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import axios from 'axios';
import logo from "../images/EMLOGO.png";

const Charter = () => {
    const [yachtListings, setYachtListings] = useState([]);
    const [filteredYachts, setFilteredYachts] = useState([]);
    const [isOpen, setIsOpen] = useState(true);
    const [filters, setFilters] = useState({
        model: '',
        cabins: '',
        passengers: '',
        minPrice: '',
        maxPrice: '', // Add maxPrice for range
        location: ''
    });
    
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch yacht data from the server
    useEffect(() => {
        const fetchYachtData = async () => {
            try {
                // Fetch the charter data first
                const response = await fetch(' https://us-central1-elite-marine-web-development.cloudfunctions.net/api/getCharters');
                if (!response.ok) {
                    throw new Error('Failed to fetch charters data');
                }
                const data = await response.json();
    
                // Convert all yacht objects to an array
                const yachtsArray = Object.entries(data).map(async ([id, yacht]) => {
                    try {
                        const imageResponse = await axios.get(` https://us-central1-elite-marine-web-development.cloudfunctions.net/api/getImage`, {
                            params: { folderPath: id }  // Use the id as the folderPath
                        });
                
                        return {
                            ...yacht,
                            id,  
                            imageUrl: imageResponse.data.imageUrl || null
                        };
                    } catch (imageError) {
                        console.error(`Failed to fetch image for yacht ID: ${id}`, imageError);
                        return {
                            ...yacht,
                            id,
                            imageUrl: null
                        };
                    }
                });
                

            
                // Wait for all promises to resolve and update the state
                const resolvedYachts = await Promise.all(yachtsArray);
                setYachtListings(resolvedYachts);
                setFilteredYachts(resolvedYachts);
                setIsLoading(false);
            } catch (error) {
                setError(error.message);
                setIsLoading(false);
            }
        };
    
        fetchYachtData();
    }, []);
    
    

    // Handle filter input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFilters({
            ...filters,
            [name]: value
        });
    };

    // Filter yachts based on search
    const handleSearch = () => {
        const filtered = yachtListings.filter((yacht) => {
            const yachtPrice = parseFloat(yacht.price); // Ensure price is a float
            const minPrice = parseFloat(filters.minPrice) || 0; // Default to 0 if not provided
            const maxPrice = parseFloat(filters.maxPrice) || Infinity; // Default to Infinity if not provided
    
            return (
                (!filters.model || yacht.name.toLowerCase().includes(filters.model.toLowerCase())) &&
                (!filters.cabins || yacht.cabins === parseInt(filters.cabins)) &&
                (!filters.passengers || yacht.passengers >= parseInt(filters.passengers)) &&
                (yachtPrice >= minPrice && yachtPrice <= maxPrice) && // Check if yacht price is within the range
                (!filters.location || yacht.location.toLowerCase().includes(filters.location.toLowerCase()))
            );
        });
        setFilteredYachts(filtered);
    };
    
    
    
    // Rendering empty state
    {filteredYachts.length === 0 && <p>No yachts found matching your criteria.</p>}
    

    // Sample images for the carousel (replace with actual image paths if available)
    const images = [
        'https://example.com/image1.jpg',
        'https://example.com/image2.jpg',
        'https://example.com/image3.jpg'
    ];

    // Loading state


    // Error state
    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="w-[100%] listings-body  mx-auto bg-em-skin text-sans">
            {/* Navbar */}
            <nav className="opacity-1 flex items-center justify-between flex-wrap p-6 z-20 fixed top-0 left-0 right-0 bg-opacity-50 backdrop-blur-md shadow-bottom-stroke">
                <div className="flex items-center flex-shrink-0 text-em-brown mr-6 lg:mr-8">
                <a href="/" className="font-sans text-3xl font-bold tracking-extra-wide">
                <img src={logo} alt="Elite Marine Logo" className="w-10"></img>
                </a>
                </div>
                <div className="block lg:hidden">
                    <button
                        onClick={() => setIsOpen(!isOpen)}
                        className="flex items-center px-3 py-2 rounded text-black-500 hover:text-black-400"
                    >
                        <svg
                            className={`fill-current h-3 w-3 ${isOpen ? 'hidden' : 'block'}`}
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                        </svg>
                        <svg
                            className={`fill-current h-3 w-3 ${isOpen ? 'block' : 'hidden'}`}
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071-7.071-1.414-1.414L10 8.586z" />
                        </svg>
                    </button>
                </div>
                <div className={`w-full block flex-grow lg:flex lg:items-center lg:w-auto ${isOpen ? 'block' : 'hidden'}`}>
                    <div className="text-1.5xl lg:flex-grow">
                        <a href="/" className="block mt-4 lg:inline-block lg:mt-0 text-em-brown mr-4 font-sans hover:text-em-select cursor-pointer">
                            HOME
                        </a>
                        <a href="/Charter" className="block mt-4 lg:inline-block lg:mt-0 text-em-brown mr-4 font-sans hover:text-em-select cursor-select">
                            CHARTER
                        </a>
                        <a href="/Listings" className="block mt-4 lg:inline-block lg:mt-0 text-em-brown mr-4 font-sans hover:text-em-select cursor-select">
                            BROKERAGE
                        </a>
                        <a href="/about" className="block mt-4 lg:inline-block lg:mt-0 text-em-brown mr-4 font-sans hover:text-em-select cursor-select">
                            ABOUT
                        </a>
                        <a href="/faq" className="block mt-4 lg:inline-block lg:mt-0 text-em-brown mr-4 font-sans hover:text-em-select cursor-select">
                            FAQ
                        </a>
                    </div>
                    <a href="/enquire">
                    <div>
                        <button className="inline-flex text-1.5xl items-center border-em-brown py-1 b text-em-brown underline">
                            ENQUIRE
                        </button>
                    </div>
                    </a>
                </div>
            </nav>

            {/* Carousel Section */}
            <div className="mt-28 max-w-[1920px] mx-auto">
                <Carousel
                    showThumbs={false}
                    autoPlay={true}
                    infiniteLoop={true}
                    showStatus={false}
                    className="rounded-lg overflow-hidden shadow-lg w-full h-[500px]"
                >
                    {images.map((image, index) => (
                        <div key={index}>
                            <img src={image} alt={`Yacht Image ${index + 1}`} className="object-cover w-full h-[500px]" />
                        </div>
                    ))}
                </Carousel>
            </div>

            {/* Filter Section */}
            <div className="bg-white shadow-lg p-6 mt-8 font-sans max-w-[1905px] mx-auto">
            <h2 className="text-2xl text-gray-800 mb-4">Filter Charters</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <input
                    type="text"
                    name="model"
                    placeholder="Model"
                    className="border rounded-lg p-3"
                    value={filters.model}
                    onChange={handleInputChange}
                />
                <input
                    type="number"
                    name="cabins"
                    placeholder="Cabins"
                    className="border rounded-lg p-3"
                    value={filters.cabins}
                    onChange={handleInputChange}
                />
                <input
                    type="number"
                    name="passengers"
                    placeholder="Passengers"
                    className="border rounded-lg p-3"
                    value={filters.passengers}
                    onChange={handleInputChange}
                />
                <input
                    type="number"
                    name="minPrice"
                    placeholder="Min Price"
                    className="border rounded-lg p-3"
                    value={filters.minPrice}
                    onChange={handleInputChange}
                />
                <input
                    type="number"
                    name="maxPrice"
                    placeholder="Max Price"
                    className="border rounded-lg p-3"
                    value={filters.maxPrice}
                    onChange={handleInputChange}
                />
                <input
                    type="text"
                    name="location"
                    placeholder="Location (cruising area)"
                    className="border rounded-lg p-3"
                    value={filters.location}
                    onChange={handleInputChange}
                />
            </div>

             {/* Container for the button */}
                <div className="flex justify-end mt-4">
                    <button onClick={handleSearch} className="bg-black w-80 mt-9 text-white rounded-lg p-3">
                        Search
                    </button>
                </div>
            </div>
            

            {/* Yacht Listings */}
            <div className="grid grid-cols-1 md:grid-cols-3 p-2 gap-6 mt-11 mb-11 max-w-[1920px] mx-auto">
            {isLoading ? (
                <div className="col-span-full flex flex-col items-center justify-center mt-20 space-y-4">
                <svg 
                    className="animate-spin h-8 w-8 text-em-select" 
                    xmlns="http://www.w3.org/2000/svg" 
                    fill="none" 
                    viewBox="0 0 24 24"
                >
                    <circle 
                        className="opacity-25" 
                        cx="12" 
                        cy="12" 
                        r="10" 
                        stroke="currentColor" 
                        strokeWidth="4"
                    ></circle>
                    <path 
                        className="opacity-75" 
                        fill="currentColor" 
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291l-2.364 2.364C5.12 20.879 8.401 22 12 22v-4c-2.485 0-4.675-1.01-6-2.709z"
                    ></path>
                </svg>
                <p className="text-lg font-semibold text-gray-700">Loading yacht listings...</p>
            </div>
            
            ) : (
                filteredYachts.map((yacht, index) => (
                    <div key={index} className="bg-white shadow-lg overflow-hidden">
                        {/* Display the yacht image or fallback text */}
                        {yacht.imageUrl ? (
                            <img 
                                src={yacht.imageUrl} 
                                alt={yacht.name} 
                                className="w-full h-48 object-cover" 
                            />
                        ) : (
                            <div className="w-full h-48 bg-gray-300 flex items-center justify-center">
                                <p>No Image Available</p>
                            </div>
                        )}

                        {/* Yacht details */}
                        <div className="p-4">
                            <h1 className="text-xl font-semibold">{yacht.name}</h1>
                            <p className="text-gray-600">Cabins: {yacht.cabins}</p>
                            <p className="text-gray-600">Passengers: {yacht.passengers}</p>
                            <p className="text-gray-600">Price: ${yacht.price}</p>
                            <p className="text-gray-600">Location: {yacht.location}</p>
                        </div>

                        {/* Enquiry button */}
                        <div className="p-4">
                            <a
                                href={`https://elite-marine-web-development.web.app/Listing?uid=${yacht.id}&service=charter`}
                                className="bg-black text-white w-full p-2 rounded-lg text-center block"
                            >
                                Enquire
                            </a>
                        </div>
                    </div>
                ))
            )}
        </div>

            <div className="segment-5 bg-em-brown text-white py-10">
        <div className="container mx-auto px-6 md:px-12 lg:px-20">
            <div className="flex flex-wrap justify-between font-sans">
                
                      <div className="logo mb-8 w-full md:w-1/4">
                        {/* <img src="path-to-logo.png" alt="Elite Marine Logo" className="w-40 mb-4"> */}
                        <p className="text-sm leading-tight">Luxury Yachts & Marine Services</p>
                      </div>

  
                      <div className="quick-links mb-8 w-full md:w-1/4">
                        <h2 className="font-bold text-lg mb-4">Quick Links</h2>
                        <ul className="space-y-2">
                          <li><a href="#" className="hover:text-gray-400">Home</a></li>
                          <li><a href="#" className="hover:text-gray-400">Charter</a></li>
                          <li><a href="#" className="hover:text-gray-400">Brokerage</a></li>
                          <li><a href="#" className="hover:text-gray-400">Sell</a></li>
                          <li><a href="#" className="hover:text-gray-400">About</a></li>
                          <li><a href="#" className="hover:text-gray-400">FAQ</a></li>
                        </ul>
                      </div>

       
                      <div className="singapore mb-8 w-full md:w-1/4">
                        <h2 className=" font-bold text-lg mb-4">Singapore Office</h2>
                        <p className="text-sm">
                          <a href="mailto:info@elite-marine.net" className="hover:text-gray-400">info@elite-marine.net</a><br></br>
                          <a href="tel:+6580536058" className="hover:text-gray-400">+65 8053 6058</a><br></br>
                          <a href="tel:+6583743641" className="hover:text-gray-400">+65 8374 3641</a><br></br>
                          29 Benoi Road, Pioneer Lot, <br></br> Singapore 629922
                        </p>
                      </div>

        
                      <div className="italy mb-8 w-full md:w-1/4">
                        <h2 className="font-bold text-lg mb-4">Italy Office</h2>
                        <p className="text-sm">
                          <a href="mailto:info@elite-marine.net" className="hover:text-gray-400">info@elite-marine.net</a><br></br>
                          <a href="tel:+393514968131" className="hover:text-gray-400">+39 351 496 8131</a><br></br>
                          Via del Parco Margherita 24, <br></br> 80121 Napoli NA, Italy
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="text-center mt-8">
                    <p className="text-sm">&copy; 2024 Elite Marine. All rights reserved.</p>
                  </div>
            
        
            </div>

        </div>
    );
};

export default Charter;
